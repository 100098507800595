import cn from 'classnames';
import { type ReactNode } from 'react';

export type AvailableColors =
	| 'hvac'
	| 'lime'
	| 'sky'
	| 'pink'
	| 'red'
	| 'amber'
	| 'slate'
	| 'black';

export interface BadgeProps {
	testId?: string;
	children: ReactNode;
	color?: AvailableColors;
	className?: string;
	size?: 'large' | 'medium' | 'small';
}

const Badge = (props: BadgeProps) => {
	const {
		testId = 'Badge',
		children,
		color = 'hvac',
		className,
		size = 'large',
	} = props;

	return (
		<div
			data-testid={testId}
			className={cn(
				'inline-flex items-center px-2 py-1',
				{
					'bg-gradient-to-r from-dark-lavender/5 via-vivid-cerise/5 to-blue-ncs/5':
						color === 'hvac',
					'bg-lime-50': color === 'lime',
					'bg-sky-50': color === 'sky',
					'bg-pink-50': color === 'pink',
					'bg-red-50': color === 'red',
					'bg-black': color === 'black',
					'bg-slate-100': color === 'slate',
					'bg-amber-50': color === 'amber',
					rounded: size === 'large' || size === 'medium',
					'rounded-sm': size === 'small',
				},
				className,
			)}
		>
			<span
				className={cn('font-medium tracking-normal', {
					'bg-gradient-to-r from-cinnabar via-vivid-cerise to-dark-lavender bg-clip-text text-transparent':
						color === 'hvac',
					'text-lime-700': color === 'lime',
					'text-sky-700': color === 'sky',
					'text-pink-700': color === 'pink',
					'text-red-700': color === 'red',
					'text-slate-600': color === 'slate',
					'text-white': color === 'black',
					'text-amber-700': color === 'amber',
					'text-sm': size === 'large',
					'text-xs': size === 'medium',
					'text-xxs leading-none': size === 'small',
				})}
			>
				{children}
			</span>
		</div>
	);
};

export default Badge;
