// Modules
import { ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';
import cn from 'classnames';
// Types
import { Colors } from '../types';

export interface CTACardLinkProps extends LinkProps {
	testId?: string;
	color: Colors;
	children: ReactNode;
	compact?: boolean;
	isStrapiInlineComponent?: boolean;
}

const CTACardLink = (props: CTACardLinkProps) => {
	const {
		testId = 'CTACardLink',
		children,
		color,
		compact = false,
		isStrapiInlineComponent = false,
		...remainingProps
	} = props;

	return (
		<Link
			data-testid={testId}
			className={cn(
				'group flex w-full text-left shadow-xl transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-2xl',
				{
					'flex-col': !isStrapiInlineComponent,
					'flex-row items-center': isStrapiInlineComponent,
				},
				{
					'rounded-3xl': !compact,
					'rounded-2xl ': compact,
				},
				{
					'shadow-pink-500/10 hover:shadow-pink-600/10':
						color === 'hvac' || color === 'pink',
					'shadow-sky-600/10 hover:shadow-sky-700/10': color === 'sky',
					'shadow-red-600/10 hover:shadow-red-700/10': color === 'red',
					'shadow-amber-600/10 hover:shadow-amber-700/10': color === 'amber',
					'shadow-lime-600/10 hover:shadow-lime-700/10': color === 'lime',
				},
			)}
			{...remainingProps}
		>
			{children}
		</Link>
	);
};

export default CTACardLink;
